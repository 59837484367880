<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: "App",
	components: {},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 20px;
}
</style>
