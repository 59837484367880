<template>
	<div>
		<NSpace align="center" justify="center">
			<NH6>Sprache wählen:</NH6>
		</NSpace>

		<NSpace align="center" justify="center">
			<NButton
				:type="locale === 'de' ? 'primary' : ''"
				:size="locale === 'de' ? 'medium' : 'small'"
				@click="languageChange('de')"
			>
				Deutsch
			</NButton>
			<NButton
				:type="locale === 'en' ? 'primary' : ''"
				:size="locale === 'en' ? 'medium' : 'small'"
				@click="languageChange('en')"
			>
				Englisch
			</NButton>
		</NSpace>
	</div>
</template>

<script>
import { NH6, NButton, NSpace } from "naive-ui";
export default {
	name: "Language",
	components: {
		NH6,
		NButton,
		NSpace,
	},
	props: ["locale"],
	data() {
		return {};
	},
	methods: {
		languageChange(newLocale) {
			this.$router.push(`/${newLocale}`);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
