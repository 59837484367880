<template>
	<div>
		<NSpace align="center" justify="center">
			<NH1>Gästebuch</NH1>
		</NSpace>

		<Language :locale="$route.params.locale" />

		<NGrid y-gap="40" :cols="1">
			<NGridItem>
				<Form :totalItems="totalItems" />
			</NGridItem>

			<NGridItem>
				<NH2>Einträge:</NH2>

				<List @pages="setTotalPages" @lastPage="setHidePageButton" />
			</NGridItem>

			<NGridItem v-if="totalPages > 1">
				<NSpace justify="center">
					<NPagination
						v-if="!useAJAXForPageLoad"
						:page-count="totalPages"
						@update:page="jumpTo"
						:page-slot="7"
					/>

					<NButton
						v-else
						type="primary"
						v-show="!hidePageButton"
						@click="loadPage(currentPage + 1)"
					>
						Lade Seite {{ currentPage + 1 }}
					</NButton>
				</NSpace>
			</NGridItem>
		</NGrid>
	</div>
</template>

<script>
import config from "../config/index.json";
import Language from "../views/Language.vue";
import Form from "../components/Form.vue";
import List from "../components/List.vue";
import {
	NH1,
	NH2,
	NGrid,
	NGridItem,
	NPagination,
	NSpace,
	NButton,
} from "naive-ui";

export default {
	name: "Guestbook",
	components: {
		Language,
		Form,
		List,
		NH1,
		NH2,
		NGrid,
		NGridItem,
		NPagination,
		NSpace,
		NButton,
	},
	props: [],
	provide: {
		useAJAXForPageLoad: config.page.load.ajax, // FIXME: Maybe this can be optimized?
	},
	data() {
		return {
			language: this.$route.params.locale,
			useAJAXForPageLoad: config.page.load.ajax, // FIXME: Maybe this can be optimized?
			itemsPerPage: config.page.load.maxPerPage,
			totalItems: 0,
			currentPage: +this.$route.params.pageID || 1,
			totalPages: 0,
			hidePageButton: false,
		};
	},
	methods: {
		handleLocaleChange() {
			this.language = this.$route.params.locale;
			this.currentPage = 1;
		},
		setTotalPages(items) {
			const totalPages = items / config.page.load.maxPerPage;

			this.totalPages = Math.ceil(totalPages);
			this.totalItems = items;
		},
		jumpTo(page) {
			if (page === 1) {
				this.$router.push(`/${this.$route.params.locale}`);
			} else {
				this.$router.push(`/${this.$route.params.locale}/page/${page}`);
			}
		},
		loadPage(nextPage) {
			if (nextPage === this.totalPages) {
				this.hidePageButton = true;
			}

			this.currentPage = nextPage;

			this.$router.push(`/${this.$route.params.locale}/page/${nextPage}`);
		},
		setHidePageButton() {
			this.hidePageButton = true;
		},
	},
	created() {
		this.handleLocaleChange();

		this.$watch(() => this.$route.params.locale, this.handleLocaleChange);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
