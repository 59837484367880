<template>
	<NCard :title="item.author.name">
		<template #header-extra>
			{{ new Date(item.created).toLocaleString("de-DE", {}) }}
			Uhr
		</template>

		<NForm @submit="handleSubmit($event, item.id)">
			<NFormItem label="Nachricht">
				<NInput
					type="textarea"
					:input-props="{ id: 'message', name: 'message' }"
					placeholder="Deine neue Nachricht"
					:value="message"
					@update:value="handleChange"
				/>
			</NFormItem>

			<NSpace justify="end">
				<NButton type="error" @click="this.$emit('reset')">Abbrechen</NButton>
				<NButton type="primary" attr-type="submit">Update</NButton>
			</NSpace>
		</NForm>
	</NCard>
</template>

<script>
import { NCard, NForm, NFormItem, NInput, NButton, NSpace } from "naive-ui";
import { updateDocument } from "../db/firestore";

export default {
	name: "ItemEdit",
	components: {
		NCard,
		NForm,
		NFormItem,
		NInput,
		NButton,
		NSpace,
	},
	props: ["item"],
	data() {
		return {
			message: this.item.message,
		};
	},
	methods: {
		handleSubmit(event, id) {
			event.preventDefault();

			updateDocument({
				data: Object.fromEntries(new FormData(event.target)),
				id,
			})
				.then(() => {
					this.$emit("reset");
				})
				.catch((error) => {
					throw new Error(error.message);
				});
		},
		handleChange(value) {
			this.message = value;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
