<template>
	<div>
		<NGrid y-gap="10" :cols="1">
			<NGridItem>
				<Item :item="item" :iteration="iteration" :hideEditButton="true" />
			</NGridItem>

			<NGridItem>
				<NSpace justify="end">
					<NButton @click="$emit('reset')"> Zurück </NButton>

					<NButton
						type="primary"
						attr-type="submit"
						:disabled="disableSubmit"
						@click="handleSubmit"
					>
						Senden
					</NButton>
				</NSpace>
			</NGridItem>
		</NGrid>
	</div>
</template>

<script>
import { createDocument } from "../db/firestore";
import Item from "./Item.vue";
import { NButton, NSpace, NGrid, NGridItem } from "naive-ui";
import { sendWelcomeEmail } from "../emails/emailjs.js";

export default {
	name: "Preview",
	components: {
		Item,
		NGrid,
		NGridItem,
		NButton,
		NSpace,
	},
	props: ["disableSubmit", "iteration", "item"],
	data() {
		return {};
	},
	methods: {
		async handleSubmit(event) {
			event.preventDefault();

			const {
				language,
				author: { name, email },
				message,
			} = this.item;

			const iteration = this.iteration;

			createDocument({
				data: {
					index: this.iteration,
					language,
					author: {
						name,
						email,
					},
					message,
					approved: false,
				},
			})
				.then((res) => {
					const {
						id,
						language,
						author: { name, email },
						message,
					} = res.documentData;

					sendWelcomeEmail({
						id,
						language,
						name,
						email,
						message,
						iteration,
					});

					this.$emit("reset");
					this.$emit("done");
				})
				.catch((error) => {
					throw new Error(error.message);
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
