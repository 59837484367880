import emailjs from '@emailjs/browser';

export const sendWelcomeEmail = ({ id, language, name, email, message } = {}) => {
	emailjs.send('service_cid04w8', 'template_a9pbtdq', { id, language, name, email, message }, "user_pGP6EiJ7A3Oef57GSmbZ3").then(function (response) {
		('SUCCESS!', response.status, response.text);
	}, function (error) {
		('FAILED...', error);
	});
};

// export const sendCancelationEmail = ({ email, name } = {}) => {
// 	sgMail.send({
// 		to: email,
// 		from: 'coding.trarbach@gmail.com',
// 		subject: 'Sorry to see you go!',
// 		text: `Goodbye ${name}, I hope to see you back sometime soon.`
// 	}).then(() => {
// 		('Cencelation mail sent.')
// 	}).catch((error) => {
// 		console.error(error)
// 	});
// };