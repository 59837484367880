<template>
	<div>
		<NH1>Eintrag löschen</NH1>

		<div v-if="loading">
			<NAlert type="info" title="Lade"> Eintrag wird geladen </NAlert>
		</div>

		<NGrid v-else y-gap="10" :cols="1">
			<NGridItem v-if="item">
				<NAlert v-if="deleted" type="success" title="Fertig">
					Eintrag erfolgreich gelöscht. Sie werden in {{ countdownTime }} Sekunden
					weitergeleitet.
				</NAlert>
			</NGridItem>

			<NGridItem>
				<NH2 v-if="item">Vorschau</NH2>

				<Item
					v-model="item"
					v-if="item"
					:item="item"
					:iteration="item.index"
					:hideEditButton="true"
				/>

				<NAlert v-else type="error" title="Fehler">
					Eintrag nicht gefunden!
				</NAlert>
			</NGridItem>
		</NGrid>
	</div>
</template>

<script>
import { deleteDocument } from "../db/firestore";
import Item from "../components/Item.vue";
import { NH1, NH2, NAlert } from "naive-ui";
export default {
	name: "Delete",
	components: {
		Item,
		NH1,
		NH2,
		NAlert,
	},
	props: {},
	data() {
		return {
			loading: true,
			item: null,
			deleted: false,
			countdownTime: 5,
			intervalID: null,
			updated: false,
		};
	},
	methods: {
		countdown() {
			this.intervalID = setInterval(() => {
				this.countdownTime--;
			}, 1000);
		},
		stopCountdown(id) {
			clearInterval(id);
		},
	},
	watch: {
		countdownTime(newValue) {
			if (newValue === 0) {
				this.stopCountdown(this.intervalID);
			}
		},
	},
	created() {
		deleteDocument({
			id: this.$route.params.id,
		})
			.then((res) => {
				this.loading = false;

				this.item = res.documentBeforeUpdate;

				this.deleted = true;

				this.countdown();

				setTimeout(() => {
					window.location = `/${res.documentBeforeUpdate.language}`;
				}, 5000);
			})
			.catch((error) => {
				this.loading = false;

				throw new Error(error.message);
			});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
