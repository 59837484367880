<template>
	<NCard :title="`${item.iteration ?? iteration}. ${item.author.name}`">
		<template #header-extra>
			Datum: {{ new Date(item.created).toLocaleString("de-DE", {}) }}
			Uhr
		</template>

		{{
			isTextTrimmed
				? `${item.message.substring(0, trimTextAfterChars)}...`
				: item.message
		}}

		<NSpace justify="end" v-if="!hideEditButton">
			<NButton @click="toggleTextPreview" v-if="hasReadMore">
				{{ isTextTrimmed ? "Mehr anzeigen" : "Weniger anzeigen" }}
			</NButton>

			<NButton type="primary" @click="this.$emit('update', item.id)">
				Edit
			</NButton>
		</NSpace>
	</NCard>
</template>

<script>
import { NCard, NButton, NSpace } from "naive-ui";

export default {
	name: "Item",
	components: {
		NCard,
		NButton,
		NSpace,
	},
	props: ["item", "iteration", "hideEditButton"],
	data() {
		return {
			trimTextAfterChars: 100,
			hasReadMore: false,
			isTextTrimmed: false,
		};
	},
	methods: {
		toggleTextPreview() {
			this.isTextTrimmed = !this.isTextTrimmed;
		},
		setReadMore(trimText) {
			if (trimText) {
				this.hasReadMore = trimText;
				this.isTextTrimmed = trimText;
			}
		},
	},
	mounted() {
		if (this.item) {
			this.setReadMore(this.item.message.length > this.trimTextAfterChars);
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
