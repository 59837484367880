<template>
	<div>
		<div>
			Erstellen von {{ $route.params.amount || amount }} dummy Einträgen
			erfolgreich.
		</div>

		<div>Sie werden in 5 Sekunden weitergeleitet.</div>
	</div>
</template>

<script>
import { createExampleDocuments } from "../db/firestore";

export default {
	name: "DummyContent",
	components: {},
	props: [],

	data() {
		return {
			amount: 20,
		};
	},
	methods: {},
	mounted() {
		createExampleDocuments({
			locale: this.$route.params.locale,
			amount: this.$route.params.amount || this.amount,
		});

		setTimeout(() => {
			window.location = `/${this.$route.params.locale}`;
		}, 5000);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
