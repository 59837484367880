/* eslint-disable vue/no-v-for-template-key */
<template>
	<div>
		<NGrid y-gap="10" :cols="1">
			<!-- eslint-disable-next-line vue/no-v-for-template-key -->
			<template v-for="item in pageItems" :key="item.id">
				<NGridItem>
					<Item v-if="item.id !== editItemID" :item="item" @update="update" />

					<ItemEdit v-if="item.id === editItemID" :item="item" @reset="reset" />
				</NGridItem>
			</template>
		</NGrid>

		<NAlert type="error" v-if="items.length === 0">
			Aktuell gibt es noch keine Gästebucheinträge
		</NAlert>
	</div>
</template>

<script>
import { readDocuments } from "../db/firestore";
import { NGrid, NGridItem, NAlert } from "naive-ui";
import Item from "./Item.vue";
import ItemEdit from "./ItemEdit.vue";
import { where, orderBy, onSnapshot } from "firebase/firestore";
import config from '../config/index.json';

export default {
	name: "List",
	components: {
		Item,
		ItemEdit,
		NGrid,
		NGridItem,
		NAlert
	},
	props: [],
	inject: ["useAJAXForPageLoad"],
	data() {
		return {
			items: [],
			pageItems: [],
			editItemID: null,
		};
	},
	methods: {
		update(id) {
			this.editItemID = id;
		},
		reset() {
			this.editItemID = null;
		},
		getTotalItems() {
			readDocuments({
				constraints: [
					where("approved", "==", true),
					where("language", "==", this.$route.params.locale),
					orderBy("created", "desc"),
				],
			})
				.then((res) => {
					const { documentsQuery } = res;

					onSnapshot(
						documentsQuery,
						(querySnapshot) => {
							this.items = querySnapshot.docs.map((doc) => ({
								id: doc.id,
								...doc.data(),
							}));

							this.items = this.items.map((document, index) => ({
								iteration: this.items.length - index,
								...document,
							}));

							// Send items to parent to measure totalPages
							this.$emit("pages", this.items.length);

							if (this.useAJAXForPageLoad) {
								this.getPageItemsAJAX();
							} else {
								this.getPageItems();
							}
						},
						(error) => {
							throw new Error(error.message);
						}
					);
				})
				.catch((error) => {
					error.message;
				});
		},
		getPageItems() {
			let startAt;
			let endAt;
			let { pageID: page = 1 } = this.$route.params;

			if (page === 1) {
				startAt = 0;
				endAt = config.page.load.maxPerPage - 1;
			} else {
				startAt =
					page * config.page.load.maxPerPage -
					config.page.load.maxPerPage;
				endAt = startAt + config.page.load.maxPerPage - 1;
			}

			const pageItems = this.items.filter(
				(_, index) => index >= startAt && endAt >= index
			);

			this.pageItems = pageItems;
		},
		getPageItemsAJAX() {
			let startAt;
			let endAt;
			let { pageID: page = 1 } = this.$route.params;

			const lastPage = Math.ceil(
				this.pageItems.length / config.page.load.maxPerPage
			);
			const totalPages = Math.ceil(
				this.items.length / config.page.load.maxPerPage
			);

			if (page === 1) {
				startAt = 0;
				endAt = config.page.load.maxPerPage - 1;
			} else {
				if (!this.pageItems.length) {
					startAt = 0;
					endAt = page * config.page.load.maxPerPage - 1;
				} else {
					startAt =
						page * config.page.load.maxPerPage -
						config.page.load.maxPerPage;
					endAt = startAt + config.page.load.maxPerPage - 1;
				}
			}

			const pageItems = this.items.filter(
				(_, index) => index >= startAt && endAt >= index
			);

			if (!this.pageItems.length || lastPage >= page) {
				this.pageItems = [...pageItems];
			} else {
				this.pageItems = [...this.pageItems, ...pageItems];
			}

			if (page >= totalPages) {
				this.$emit("lastPage");
			}
		},
	},
	created() {
		this.getTotalItems();

		this.$watch(() => this.$route.params, this.getTotalItems);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
