<template>
	<div>
		<NForm v-if="!preview">
			<NH2>Erstelle einen Eintrag:</NH2>

			<NFormItem label="Name">
				<NGrid y-gap="10" :cols="1">
					<NGridItem>
						<NInput
							type="text"
							:input-props="{
								id: form.fields.name.fieldName,
								name: form.fields.name.fieldName,
							}"
							:placeholder="form.fields.name.placeholder"
							:value="form.fields.name.value"
							@update:value="
								(value) => updateValue(value, form.fields.name.fieldName)
							"
						/>
					</NGridItem>

					<NGridItem
						v-model="form.fields.name.invalid"
						v-if="form.fields.name.invalid"
					>
						<NAlert type="error">
							{{ form.fields.name.error }}
						</NAlert>
					</NGridItem>
				</NGrid>
			</NFormItem>

			<NFormItem label="E-Mail">
				<NGrid y-gap="10" :cols="1">
					<NGridItem>
						<NInput
							type="email"
							:input-props="{
								id: form.fields.email.fieldName,
								name: form.fields.email.fieldName,
							}"
							:placeholder="form.fields.email.placeholder"
							:value="form.fields.email.value"
							@update:value="
								(value) => updateValue(value, form.fields.email.fieldName)
							"
						/>
					</NGridItem>

					<NGridItem
						v-model="form.fields.email.invalid"
						v-if="form.fields.email.invalid"
					>
						<NAlert type="error">
							{{ form.fields.email.error }}
						</NAlert>
					</NGridItem>
				</NGrid>
			</NFormItem>

			<NFormItem label="Nachricht">
				<NGrid y-gap="10" :cols="1">
					<NGridItem>
						<NInput
							type="textarea"
							:input-props="{
								id: form.fields.message.fieldName,
								name: form.fields.message.fieldName,
							}"
							:placeholder="form.fields.message.placeholder"
							:value="form.fields.message.value"
							@update:value="
								(value) => updateValue(value, form.fields.message.fieldName)
							"
						/>
					</NGridItem>

					<NGridItem
						v-model="form.fields.email.invalid"
						v-if="form.fields.message.invalid"
					>
						<NAlert type="error">
							{{ form.fields.message.error }}
						</NAlert>
					</NGridItem>
				</NGrid>
			</NFormItem>

			<NSpace justify="end">
				<NButton
					type="info"
					@click="handlePreview"
					:disabled="form.preventSubmit"
				>
					Vorschau
				</NButton>
			</NSpace>
		</NForm>

		<div v-else>
			<NH2>Dein Eintrag:</NH2>

			<Preview
				:disableSubmit="form.preventSubmit"
				:item="previewData[0]"
				:iteration="totalItems + 1"
				@reset="resetPreview"
				@done="handleResetForm"
			/>
		</div>

		<NButton @click="createMessage">Message</NButton>
	</div>
</template>

<script>
import {
	NH2,
	NForm,
	NFormItem,
	NButton,
	NInput,
	NAlert,
	NGridItem,
	NGrid,
	NSpace
} from "naive-ui";
import validator from "validator";
import Preview from "./Preview.vue";

export default {
	name: "Form",
	components: {
		Preview,
		NH2,
		NForm,
		NFormItem,
		NButton,
		NInput,
		NAlert,
		NGrid,
		NGridItem,
		NSpace,
	},
	props: ["totalItems"],
	data() {
		return {
			name: "",
			form: {
				fields: {
					name: {
						invalid: false,
						fieldName: "name",
						placeholder: "Dein Name",
						error: "Bitte gib einen Namen an.",
						value: "",
					},
					email: {
						invalid: false,
						fieldName: "email",
						placeholder: "Deine E-Mail Adresse",
						error: "Keine gültige E-Mail Adresse.",
						value: "",
					},
					message: {
						invalid: false,
						fieldName: "message",
						placeholder: "Deine Nachricht",
						error: "Bitte gib eine Nachricht an.",
						value: "",
					},
				},
				preventSubmit: true,
			},
			preview: false,
			previewData: [],
		};
	},
	methods: {
		resetPreview() {
			this.preview = false;
		},
		updateValue(value, fieldName) {
			this.handleChange(value, fieldName);
		},
		handleChange(value, fieldName) {
			this.form.fields[fieldName].edited = true;
			this.form.fields[fieldName].value = value;

			switch (fieldName) {
				case "name":
				case "message":
					if (validator.isEmpty(value) || !value.trim()) {
						this.form.fields[fieldName].invalid = true;
					} else {
						this.form.fields[fieldName].invalid = false;
					}

					break;
				case "email":
					if (validator.isEmail(value.trim())) {
						this.form.fields[fieldName].invalid = false;
					} else {
						this.form.fields[fieldName].invalid = true;
					}

					break;
				default:
					break;
			}

			this.form.preventSubmit = Object.values(this.form.fields).some(
				(item) => item.invalid
			);
		},
		handlePreview(event) {
			this.preview = true;

			const { name, email, message } = Object.fromEntries(
				new FormData(event.target.closest("form"))
			);

			this.previewData = [
				{
					language: this.$route.params.locale,
					author: {
						name,
						email,
					},
					message,
					approved: false,
					created: Date.now(),
				},
			];
		},
		handleResetForm() {
			this.previewData = [];

			for (const field in this.form.fields) {
				this.form.fields[field].value = "";
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
