import { createRouter, createWebHistory } from 'vue-router';
import Language from '../views/Language.vue';
import Guestbook from '../views/Guestbook.vue';
import Approve from '../views/Approve.vue';
import Delete from '../views/Delete.vue';
import DummyContent from '../views/DummyContent.vue';

const routes = [
	{
		path: '/',
		name: 'Language',
		component: Language
	},
	{
		path: '/:locale',
		name: 'Home',
		component: Guestbook
	},
	{
		path: '/:locale/page/:pageID',
		component: Guestbook,
	},
	{
		path: '/:locale/beispiel-eintraege/:amount?',
		component: DummyContent,
	},
	{
		path: '/eintrag/freischalten/:id',
		name: 'Eintrag freischalten',
		component: Approve
	},
	{
		path: '/eintrag/entfernen/:id',
		name: 'Eintrag löschen',
		component: Delete
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;